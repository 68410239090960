import First from "../../assets/images/img1.jpg";
import Second from "../../assets/images/img2.jpg";
import Third from "../../assets/images/img3.jpg";
import Fourth from "../../assets/images/img4.jpg";
import Fifth from "../../assets/images/img5.jpg";

export const images = [
  { title: "Image 1", subtitle: " Image desc", img: First },
  { title: "Image 2", subtitle: " Image desc", img: Second },
  { title: "Image 3", subtitle: " Image desc", img: Third },
  { title: "Image 4", subtitle: " Image desc", img: Fourth },
  { title: "Image 4", subtitle: " Image desc", img: Fifth },
];
